<script>
import Layout from "./../../../layouts/main";
import appConfig from "../../../../app.config";

export default {
  page: {
    meta: [{ name: "Bitget integration", content: appConfig.description }],
  },
  data() {
    return {
    };
  },
  components: {
    Layout
  },
};
</script>

<template>
  <Layout>
      <div class="row">
            <div class="col-lg-12">
                <!-- end card -->

                <div class="row justify-content-evenly">

                  <!-- Base Example -->
                  <div class="accordion" id="default-accordion-example">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          Step 1. Registration on the exchange
                        </button>
                      </h2>
                      <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#default-accordion-example">
                        <div class="accordion-body">
                          <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                            <i class="las la-info-circle label-icon"></i>
                            The exchange may make its own visual changes.
                            Read all the tips on the exchange carefully!
                          </div>
                          <p>Please follow the link to start registering on Bitget <a href="https://www.bitget.com/ru/referral/register?from=referral&clacCode=8VQDFRMK" target="_blank">https://www.bitget.com</a>.</p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          Step 2. Top up your account on the exchange
                        </button>
                      </h2>
                      <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#default-accordion-example">
                        <div class="accordion-body">
                          <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                            <i class="las la-info-circle label-icon"></i>
                            To trade on the stock exchange you will need <strong>USDT</strong>.
                          </div>
                          <p>
                            In order for our system to make transactions, you must have USDT in your account.
                            <br>
                            You have 2 options:
                          </p>
                          <ol>
                            <li>Transfer assets (USDT) from another exchange or wallet by going to this <a href="https://www.bitget.com/ru/asset/recharge?coinId=2" target="_blank">link</a>;</li>
                            <li>Buy the desired asset (USDT) for fiat money by clicking on this <a href="https://www.bitget.com/ru/buy-sell-crypto" target="_blank">link</a>.</li>
                          </ol>
                          <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                            <i class="las la-info-circle label-icon"></i>
                            Follow the instructions on Bitget to complete the steps!
                          </div>
                          <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                            <i class="las la-info-circle label-icon"></i>
                            Please note that your assets must be stored in the account with which the specific bot of our system works.
                            For a spot bot this is a spot account, and for a futures bot this is a futures account.
                          </div>
                          <p>If everything was done correctly, you will see your assets by clicking on this <a href="https://www.bitget.com/ru/asset">link</a>.</p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="heading4">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                          Step 3. Create an API key
                        </button>
                      </h2>
                      <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#default-accordion-example">
                        <div class="accordion-body">
                          <div class="alert alert-warning" role="alert">
                            <i class="las la-info-circle"></i>
                            We strongly recommend you to enable two-factor authentication.
                          </div>
                          <p>To create an API key, follow this <a href="https://www.bitget.com/ru/account/newapi" target="_blank">link</a>.</p>

                          <img src="/Instruction/Bitget/api-keys.png" class="img-fluid border-dotted" />
                          <br><br>
                          <p>Press the button «Create API Key». You will see a pop-up window where you need to select «System-generated API key». </p>
                          <img src="/Instruction/Bitget/select-api-key.png" class="img-fluid border-dotted col-md-6"/>
                          <br><br>
                          <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                            <i class="las la-info-circle label-icon"></i>
                            For integration we need <strong>read permissions</strong>.
                          </div>
                          <p>Next, fill out the form and tick the following fields:</p>
                          <img src="/Instruction/Bitget/create-api-key-1.png" class="img-fluid border-dotted col-md-6"/>
                          <br>
                          <br>
                          <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-6" role="alert">
                            <i class="las la-info-circle label-icon"></i>
                            For spot bot, check <strong>spot and margin</strong>.
                          </div>

                          <img src="/Instruction/Bitget/create-api-key-2.png" class="img-fluid border-dotted col-md-6"/>
                          <br><br>

                          <div class="alert alert-warning alert-dismissible alert-label-icon label-arrow fade show col-md-6" role="alert">
                            <i class="las la-info-circle label-icon"></i>
                            For security, make sure you have added our server's IP address <strong>52.28.48.36</strong>.
                          </div>

                          <div class="alert alert-warning alert-dismissible alert-label-icon label-arrow fade show col-md-6" role="alert">
                            <i class="las la-info-circle label-icon"></i>
                            Please save the generated keys in a safe place.You will need them later.
                            For security reasons, you cannot go back and view it again.
                            If you lose it, you will need to do all the steps again!
                          </div>

                          <div class="alert alert-danger alert-dismissible alert-label-icon label-arrow fade show col-md-6" role="alert">
                            <i class="ri-error-warning-line label-icon"></i>
                            <strong>Do not check</strong> the <strong>Transfer</strong> and <strong>Output</strong> checkboxes in the Wallet section!
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="heading5">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                          Step 4. Integration with our system
                        </button>
                      </h2>
                      <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#default-accordion-example">
                        <div class="accordion-body">
                          <p>Follow the <a href="/api-keys" target="_blank">link</a> in order to add an API key to our system.</p>
                          <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                            <i class="las la-info-circle label-icon"></i>
                            To fill in all required fields, use the data from the previous step.
                          </div>
                          <img src="/Instruction/Bitget/j2p-add-api-key.png" class="img-fluid border-dotted col-md-6"/>
                          <br><br>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="heading6">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                          Step 5. Create a bot in our system
                        </button>
                      </h2>
                      <div id="collapse6" class="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#default-accordion-example">
                        <div class="accordion-body">
                          <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                            <i class="las la-info-circle label-icon"></i>
                            Carefully read the information on the bot creation page!
                          </div>
                          <p>To create a trading bot, go to the specified <a href="/bots" target="_blank">link</a> </p>

                          <img src="/Instruction/Bitget/j2p-create-bot.png" class="img-fluid border-dotted col-md-6" />
                          <br>
                          <br>
                          <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                            <i class="las la-info-circle label-icon"></i>
                            The amount of your deposit affects the number of active trades and the amount of trades.
                          </div>
                          <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                            <i class="las la-info-circle label-icon"></i>
                            We recommend having as many active trades as possible.
                          </div>
                          <p>
                            Ensure that <a href="/bots" target="_blank">your bots</a> are activated.
                          </p>
                          <img src="/Instruction/Bitget/bots-list.png" class="img-fluid border-dotted col-md-7" />
                        </div>
                  </div>
                </div>
            </div><!--end col-->.
        </div><!--end row-->
        </div><!--end row-->
        </div><!--end row-->
    </Layout>
</template>
